let readyNotifications = function () {
  // notifications
  window.notifications = {
    key: 'notifications',
    loadExists: function () {
      let exists = null;
      try {
        exists = JSON.parse(localStorage.getItem(notifications.key));
      } catch (e) {
        exists = {};
      }
      if (exists === null) {
        exists = {};
      }
      return exists;
    },
    push: function (list) {
      let exists = notifications.loadExists();
      for (let item of list) {
        if (typeof exists[item.ID] == 'undefined') {
          exists[item.ID] = item;
        }
      }
      localStorage.setItem(notifications.key, JSON.stringify(exists));
    },
    remove: function (id) {
      let exists = notifications.loadExists();
      if (typeof exists[id] != 'undefined') {
        delete exists[id];
        localStorage.setItem(notifications.key, JSON.stringify(exists));
      }
      notifications.show();
    },
    show: function (selector) {
      if (typeof selector != 'undefined') {
        notifications.selector = selector;
      }

      let exists = notifications.loadExists(),
        sorted = Object.keys(exists).sort(function (a, b) {
          return parseInt(b, 10) - parseInt(a, 10);
        }), output = '', $dom = $(notifications.selector);

      if ($dom.length < 1) {
        return '';
      }

      let num = 0
      for (let i = 0, item; i < 2; i++) {
        item = exists[sorted[i]];
        if (typeof item != 'undefined') {
          output += `<div class="notification__item-wrap" data-id="${item.ID}">
    <div class="notification__item">
      <div class="notification__block">
        <div class="notification__text">${item.NotificationText}</div>
      </div>
      <div class="notification__buttons">
        <a href="/lk/my_lessons" class="btn btn--w260 btn-light">Перейти</a></div>
      <a href="" class="notification__close"></a>
    </div>
  </div>`;
          num++;
        }
      }

      $(notifications.selector + "__empty-space").attr('data-num', num).data('num', num);

      $dom.html(output).hide().show();

      $dom.find('.notification__close').on('click', function (e) {
        e.preventDefault();
        let $t = $(this), $wrapper = $t.closest('.notification__item-wrap'), id = $wrapper.attr('data-id');
        $wrapper.slideUp();
        notifications.remove(id);
      });
    }
  };
};

$(document).ready(readyNotifications);
$(document).on('page:load', readyNotifications);
