// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// scripts
require("jquery");
require("jquery-ui-dist/jquery-ui");
require("jquery-ujs");
window.$ = window.jQuery361 = jQuery; // fuck! где-то перебивается jQuery =(

window.bootstrap = require("bootstrap");

// import "channels"
require("packs/jquery/DragDropTouch");
require("packs/jquery/slick");
require("packs/jquery/script");
require("packs/jquery/lessons");
require("packs/jquery/notifications");
require('packs/jquery/core_functions');
require('packs/jquery/loader');
require('packs/jquery/multipart-form');
