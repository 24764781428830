let readyLessons = function () {
  
  let slider = $('.lesson__carousel'), disableSlick = function () {
    $('.lesson__arrows-left').addClass('lesson__arrows-left--disable');
    $('.lesson__arrows-right').addClass('lesson__arrows-right--disable');
    
    slider.slick("slickSetOption", "accessibility", false);
    slider.slick("slickSetOption", "draggable", false);
    slider.slick("slickSetOption", "swipe", false);
    slider.slick("slickSetOption", "touchMove", false);
  }, enableSlick = function () {
    $('.lesson__arrows-left').removeClass('lesson__arrows-left--disable');
    $('.lesson__arrows-right').removeClass('lesson__arrows-right--disable');
    
    slider.slick("slickSetOption", "accessibility", true);
    slider.slick("slickSetOption", "draggable", true);
    slider.slick("slickSetOption", "swipe", true);
    slider.slick("slickSetOption", "touchMove", true);
  };
  
  slider.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    infinite: false,
  }).on('afterChange', function (event, slick, currentSlide) {
    // youtube
    
    let $slider = $('.lesson__carousel-item').eq(currentSlide), video = $slider.attr('data-video');
    $('.youTube-XXX').remove();
    if (video) {
      let is_YouTube = video.match(/youtube|youtu.be/) != null;
      if (is_YouTube) {
        $slider.append('<iframe class="youTube-XXX" width="560" height="315" src="' + video + '?controls=1&modestbranding=0&rel=0&showinfo=0\'" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');
      } else {
        $slider.find('video').remove();
        $slider.append('<video class="youTube-XXX" width="560" height="315" controls src="' + video + '" poster="' + ($slider.find('img').length ? $slider.find('img').eq(0).attr('src') : '') + '"></video>')
      }
    }
    
    // шкала времени
    $('.lesson__line-z').css({
      width: (currentSlide / ($('.lesson__carousel-item').length - 1)) * 100 + '%'
    });
    
    let $active = $('.slick-active');
    
    // TODO Cерег, этот блок для тебя =)
    if ($active.hasClass('lesson__pull')) {
      $('.slick-list').off('click');
      
      if ($active.hasClass('js--lesson_poll') && $active.find('input[type=radio]:checked').length < 1) {
        disableSlick();
      } else if ($active.hasClass('js--lesson_combine') && $active.find('.lesson__item--set').length < 6) {
        disableSlick();
      } else if ($active.hasClass('js--lesson_draggable') && $active.find('.js--lesson_items--to .lesson__d-item').length < 1) {
        disableSlick();
      }
    } else {
      enableSlick();
    }
    
    if (currentSlide === ($('.lesson__carousel-item').length - 1)) {
      $('.js--lesson__arrows-right').addClass('lesson__arrows-right--disable'); // если последний, вправо низзя =)
      
      $('.js--pass_lesson').trigger('click'); // отправляем форму
    }
    
    if (currentSlide == 0) {
      $('.js--lesson__arrows-left').addClass('lesson__arrows-left--disable'); // если 1-й, влево низзя =)
    }
  });
  
  $('.js--lesson__arrows-right').on('click', function (e) {
    e.preventDefault();
    if (!$(this).hasClass('lesson__arrows-right--disable')) {
      slider.slick('slickNext');
    }
  });
  $('.js--lesson__arrows-left').on('click', function (e) {
    e.preventDefault();
    if (!$(this).hasClass('lesson__arrows-left--disable')) {
      slider.slick('slickPrev');
    }
  }).addClass('lesson__arrows-left--disable'); // первоначально влево низзя =)
  // ХЗ что там дальше
  
  
  let $document = $(document), $body = $('body');
  
  // фиксим модальное окно через окно, да и дальше все события вешаем на $document или $body!!!
  $document
    .on('change', '.js--lesson_poll input[type=radio]', function (e) {
      let $t = $(this), $f = $t.closest('form');
      $f.find('.lesson__tip').addClass('d-none');
      $f.find('.lesson__button').removeAttr('disabled');
    })
    .on('click', '.js--lesson_poll .lesson__button', function (e) {
      e.preventDefault()
      let $t = $(this), $f = $t.closest('form'), v = $f.find('input[type=radio]:checked').val();
      if (v.toString() === $f.data('right').toString()) {
        $f.find('.lesson__tip--right').removeClass('d-none');
      } else {
        $f.find('.lesson__tip--wrong').removeClass('d-none');
      }
      
      $(this).val('Далее').off('click').on('click', function () {
        $('.js--lesson__arrows-right').trigger('click');
      });
      enableSlick();
    })
    .on('click', '.js--lesson_combine .lesson__item', function (e) {
      e.preventDefault();
      let $t = $(this), $items = $t.closest('.lesson__items').find('.lesson__item'), $f = $t.closest('form'),
        $lb = $f.find('.lesson__button').attr('disabled', 'disabled');
      
      if (!$f.data('pairs')) {
        $f.data('pairs', []);
      }
      
      $f.find('.lesson__tip').addClass('d-none');
      
      $items.each(function (id) {$(this).data('id', id);});
      
      if ($t.hasClass('lesson__item--set') && $t.attr('data-pairs')) {
        let dp = $t.attr('data-pairs'), spl = dp.split(',');
        for (let i = 0, len = spl.length; i < len; i++) {
          $items.eq(spl[i]).removeAttr('data-pairs');
          $items.get(spl[i]).className = 'lesson__item';
        }
        
        $f.data('pairs', $f.data('pairs').filter(function (value, index, arr) {
          return value !== dp;
        }));
      }
      if ($t.get(0).className.match(/lesson__item--\d+/) !== null) {
        return false;
      }
      $t.toggleClass('lesson__item--selected');
      
      let $selected = $items.filter('.lesson__item--selected'), pairs = '';
      if ($selected.length == 2) {
        pairs = $.map($selected, function (i) { return $(i).data('id') }).sort().join();
        $selected.addClass('lesson__item--set').removeClass('lesson__item--selected');
      }
      
      if (pairs === '0,1') {
        $selected.eq(0).addClass('lesson__item--1');
        $items.eq(0).attr('data-pairs', pairs);
        $items.eq(1).attr('data-pairs', pairs);
        $f.data('pairs', $.merge($f.data('pairs'), [pairs]));
      } else if (pairs === '0,3') {
        $selected.eq(0).addClass('lesson__item--3');
        $items.eq(0).attr('data-pairs', pairs);
        $items.eq(3).attr('data-pairs', pairs);
        $f.data('pairs', $.merge($f.data('pairs'), [pairs]));
      } else if (pairs === '0,5') {
        $selected.eq(0).addClass('lesson__item--4');
        $items.eq(0).attr('data-pairs', pairs);
        $items.eq(5).attr('data-pairs', pairs);
        $f.data('pairs', $.merge($f.data('pairs'), [pairs]));
      } else if (pairs === '1,2') {
        $selected.eq(1).addClass('lesson__item--2');
        $items.eq(1).attr('data-pairs', pairs);
        $items.eq(2).attr('data-pairs', pairs);
        $f.data('pairs', $.merge($f.data('pairs'), [pairs]));
      } else if (pairs === '1,4') {
        $selected.eq(1).addClass('lesson__item--5');
        $items.eq(1).attr('data-pairs', pairs);
        $items.eq(4).attr('data-pairs', pairs);
        $f.data('pairs', $.merge($f.data('pairs'), [pairs]));
      } else if (pairs === '2,3') {
        $selected.eq(0).addClass('lesson__item--1');
        $items.eq(2).attr('data-pairs', pairs);
        $items.eq(3).attr('data-pairs', pairs);
        $f.data('pairs', $.merge($f.data('pairs'), [pairs]));
      } else if (pairs === '2,5') {
        $selected.eq(0).addClass('lesson__item--3');
        $items.eq(2).attr('data-pairs', pairs);
        $items.eq(5).attr('data-pairs', pairs);
        $f.data('pairs', $.merge($f.data('pairs'), [pairs]));
      } else if (pairs === '3,4') {
        $selected.eq(1).addClass('lesson__item--2');
        $items.eq(3).attr('data-pairs', pairs);
        $items.eq(4).attr('data-pairs', pairs);
        $f.data('pairs', $.merge($f.data('pairs'), [pairs]));
      } else if (pairs === '4,5') {
        $selected.eq(0).addClass('lesson__item--1');
        $items.eq(4).attr('data-pairs', pairs);
        $items.eq(5).attr('data-pairs', pairs);
        $f.data('pairs', $.merge($f.data('pairs'), [pairs]));
      }
      
      if ($f.data('pairs').length === 3) {
        $lb.removeAttr('disabled');
      }
    })
    .on('click', '.js--lesson_combine .lesson__button', function (e) {
      e.preventDefault();
      let $t = $(this), $f = $t.closest('form'), p = $f.data('pairs').sort().join(','), r = $f.data('right');
      if (p === r) {
        $f.find('.lesson__tip--right').removeClass('d-none');
        $(this).val('Далее').off('click').on('click', function () {
          $('.js--lesson__arrows-right').trigger('click');
        });
        enableSlick();
      } else {
        
        
        //setTimeout(function () {
        for (let i = 0, len = r.length; i < len; i++) {
          $f.find('.lesson__item').eq(r[i]).trigger('click');
        }
        enableSlick();
        $f.find('.lesson__items').addClass('lesson__items--green');
        $t.val('Далее').off('click').on('click', function () {
          $('.js--lesson__arrows-right').trigger('click');
        });
        $f.find('.lesson__tip--wrong').removeClass('d-none');
        //}, 3000);
        
        //disableSlick();
      }
    })
    .on('click', '.js--lesson_draggable .js--lesson_items--from .lesson__d-item', function (e) {
      let $t = $(this), $f = $t.closest('form'), $to = $f.find('.js--lesson_items--to').eq(0),
        $tips = $f.find('.lesson__tip').addClass('d-none'), r = $f.data('right').split(',').sort(),
        $button = $f.find('.lesson__button');
      if ($to.find('.lesson__d-item[data-id="' + $t.data('id') + '"]').length < 1) {
        $to.append($t.clone());
        $t.addClass('d-none');
      }
      
      let $items = $to.find('.lesson__d-item');
      $button.attr('disabled', 'disabled');
      if ($items.length) {
        $button.removeAttr('disabled');
      }
    })
    .on('click', '.js--lesson_draggable .js--lesson_items--to .lesson__d-item', function (e) {
      let $t = $(this), $f = $t.closest('form'), $from = $f.find('.js--lesson_items--from').eq(0),
        $to = $f.find('.js--lesson_items--to').eq(0), $items = $to.find('.lesson__d-item'),
        $src = $from.find('.lesson__d-item[data-id="' + $t.data('id') + '"]');
      
      $f.find('.lesson__tip').addClass('d-none');
      
      if ($items.length < 1) {
        $button.attr('disabled', 'disabled');
      }
      
      if ($src.length) {
        $src.removeClass('d-none');
      }
      $t.remove();
    })
    .on('click', '.js--lesson_draggable .lesson__button', function (e) {
      e.preventDefault();
      let $t = $(this), $f = $t.closest('form'), $from = $f.find('.js--lesson_items--from').eq(0),
        $to = $f.find('.js--lesson_items--to').eq(0),
        $tips = $f.find('.lesson__tip'), r = $f.data('right').split(',').sort(),
        $items = $to.find('.lesson__d-item');
      
      let ids = [];
      $items.each(function () {
        ids.push($(this).data('id'));
      });
      if (ids.sort().join(',') === r.join(',')) {
        $tips.filter('.lesson__tip--right').removeClass('d-none');
      } else {
        for (let i = 0, len = r.length; i < len; i++) {
          $f.find('.lesson__d-item--' + r[i]).addClass('lesson__d-item--green');
        }
        $tips.filter('.lesson__tip--wrong').removeClass('d-none');
      }
      
      $(this).val('Далее').off('click').on('click', function () {
        $('.js--lesson__arrows-right').trigger('click');
      });
      enableSlick();
    })
    .on('click', '.js--lesson_true_or_false .lesson__true-or-false-button', function (e) {
      e.preventDefault();
      let $t = $(this), $f = $t.closest('form');
      $f.find('.lesson__true-or-false-button--active').removeClass('lesson__true-or-false-button--active');
      $f.find('.lesson__button').removeAttr('disabled');
      $t.addClass('lesson__true-or-false-button--active');
    })
    .on('click', '.js--lesson_true_or_false .lesson__button', function (e) {
      e.preventDefault()
      let $t = $(this), $f = $t.closest('form'), v = $f.find('.lesson__true-or-false-button--active').data('variant');
      
      if (v.toString() === $f.data('is-true').toString()) {
          $f.find('.lesson__tip--right').removeClass('d-none');
        } else {
          $f.find('.lesson__tip--wrong').removeClass('d-none');
      }
  
      $t.val('Далее').off('click').on('click', function () {
        $('.js--lesson__arrows-right').trigger('click');
      });
      enableSlick();
    });
};

$(document).ready(readyLessons);
$(document).on('page:load', readyLessons);
